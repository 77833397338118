export default class {
    constructor({
        id,
        filtersHandle,
        dropdownHandle,
        videosContHandle,
        paginationContHandle,
        refresh,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const filterToggle = el.querySelector(filtersHandle);
        const dropdowns = form ? form.querySelectorAll(`${dropdownHandle} > input`) : null;
        const filters = form ? form.querySelectorAll('label > input[name]') : null;
        const videosCont = el.querySelector(videosContHandle);
        const paginationCont = el.querySelector(paginationContHandle);

        function handleFilterToggle() {
            const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
            if (viewportWidth <= 1123) {
                filterToggle.classList.toggle('hidden');
            }
        }
        function handleDropdown({ currentTarget }) {
            dropdowns.forEach(d => {
                d.checked = d.id === currentTarget.id && currentTarget.checked;
            });
        }
        function handleFilter({ currentTarget }) {
            const dropdown = currentTarget.closest(dropdownHandle);
            const dropdownInput = dropdown.querySelector('input');
            const dropdownText = dropdown.querySelector('label > span');
            const activeFilter = currentTarget.nextElementSibling;
            const adjacentFilters = dropdown.querySelectorAll('.scroll span');

            adjacentFilters.forEach(a => {
                a.parentElement.classList.toggle('highlight', a === activeFilter);
            })

            dropdownInput.checked = false;
            dropdownText.innerHTML = `${dropdownText.getAttribute('data-title')} <span>${activeFilter.textContent}</span>`;

            const formData = new FormData(form);

            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({ // eslint-disable-line no-undef
                    event: 'getRclassEvents',
                    eventCategory: 'VideoFilter',
                    eventAction: 'click',
                    eventLabel: currentTarget.getAttribute('data-event-label'),
                    eventValue: currentTarget.getAttribute('data-event-value'),
                });
            }

            fetch(`/shared/video-listing?${new URLSearchParams(formData)}`)
            .then(response => response.json())
            .then(json => {
                const { videoListingsMarkup, paginationLinksMarkup } = json;

                    videosCont.innerHTML = videoListingsMarkup;
                    paginationCont.innerHTML = paginationLinksMarkup;
                    refresh(videosCont);
                })
                .catch(err => console.log(err)); // eslint-disable-line no-console
        }

        if (dropdowns && filters) {
            dropdowns.forEach(d => {
                d.addEventListener('change', handleDropdown);
            });
            filters.forEach(f => {
                f.addEventListener('change', handleFilter);
            });
        }

        filterToggle.addEventListener('click', handleFilterToggle);
    }
}
