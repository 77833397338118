export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const li = el.querySelectorAll('li');
        
        function toggleAccordion(e) {
            const currentLi = e.currentTarget;
        
            Array.from(li).forEach(l => {
                if (l !== currentLi) {
                    l.classList.remove('active');
                    l.classList.add('inactive');
                    l.querySelector('details').removeAttribute('open');
                } else {
                    l.classList.toggle('active');
                    l.classList.remove('inactive');
                }
            });
        
            const activeItems = el.querySelectorAll('.active');
            if (activeItems.length === 0) {
                el.querySelectorAll('.inactive').forEach(item => {
                    item.classList.remove('inactive');
                });
            }
        }
        
        Array.from(li).forEach(l => {
            l.addEventListener('click', toggleAccordion);
        });
    }
}
