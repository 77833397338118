export default class {
	constructor({
		id,
        dotTriggerHandle,
        applicationHandle,
        activeClass,
	}) {
		const el = document.getElementById(id);
        const trigger = Array.from(el.querySelectorAll(dotTriggerHandle));

        function handleContent(e) {
            const currentTrigger = e.currentTarget;

            Array.from(trigger).forEach(l => {
                if (l !== currentTrigger) {
                    l.classList.remove(activeClass);
                } else {
                    l.classList.toggle(activeClass);
                }
            });

            // Reposition the content if it goes outside the viewport
            const application = currentTrigger.querySelector(applicationHandle);
            const applicationCont = application.getBoundingClientRect();
            const viewportWidth = window.innerWidth;

            if (applicationCont.right > viewportWidth) {
                application.style.left = 'auto';
                application.style.right = '11px';
            } else if (applicationCont.left < 0) {
                application.style.left = '11px';
            }
        }

        Array.from(trigger).forEach(t => {
            t.addEventListener('click', handleContent);
        });
	}
}
