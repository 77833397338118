import Swiper, { Navigation, Pagination } from 'swiper';

export default class {
    constructor({
        id,
        swiperImageWrapperHandle,
        lightboxImageHandle,
        expandHandle,
        closeHandle,
        activeClass,
        events,
        actions,
    }) {
        const el = document.getElementById(id);
        const swiperHandle = el.querySelector('.swiper');
        const next = el.querySelector('.swiper-button-next');
        const prev = el.querySelector('.swiper-button-prev');
        const pagination = el.querySelector('.swiper-pagination');
        const swiperImageWrapper = el.querySelectorAll(swiperImageWrapperHandle);
        const swiperImage = Array.from(swiperImageWrapper).map(image => image.querySelector('img'));
        const lightboxImage = el.querySelector(lightboxImageHandle);
        const expand = document.getElementById(expandHandle);
        const close = el.querySelector(closeHandle);
        const main = document.querySelector('main'); // targeting for z-index purposes

        new Swiper(swiperHandle, { // eslint-disable-line no-new
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            pagination: {
                el: pagination,
            },
            loop: true,
            slidesPerView: 1,
        });

        // State variables
        let activeIndex = null;

        // Helper functions
        function setLightboxImage() {
            const src = swiperImage[activeIndex].getAttribute('data-src');
            lightboxImage.setAttribute('src', src);
        }

        function handleKeyup(e) {
            // Only care about escape key
            if (e.keyCode !== 27) return;

            events.emit(actions.unlockScroll);
            el.classList.remove(activeClass);
            main.style.zIndex = ''; // reset <main> z-index
        }

        // Event handler functions
        function handleOpens(e) {
            e.preventDefault();
            activeIndex = swiperHandle.swiper.realIndex;
            setLightboxImage();
            events.emit(actions.lockScroll);
            el.classList.add(activeClass);
            main.style.zIndex = '1000'; // need this since <main> is set to z-index: 2

            document.addEventListener('keyup', handleKeyup);
        }
        
        function handleClose() {
            events.emit(actions.unlockScroll);
            el.classList.remove(activeClass);
            main.style.zIndex = ''; // reset <main> z-index
            
            document.removeEventListener('keyup', handleKeyup);
        }

        function handleLoad() {
            lightboxImage.style.width = '100%';
            lightboxImage.style.height = 'auto';

            if (lightboxImage.offsetHeight > lightboxImage.parentElement.offsetHeight) {
                lightboxImage.style.width = 'auto';
                lightboxImage.style.height = '100%';
            }
        }

        // Add event listeners
        Array.from(swiperImageWrapper).forEach(() => {
            expand.addEventListener('click', handleOpens);
        });
        lightboxImage.addEventListener('load', handleLoad);
        close.addEventListener('click', handleClose);
    }
}
