import YouTubePlayer from 'youtube-player';

export default class {
    constructor({
        id,
        youtubeVideoId,
    }) {
        const el = document.getElementById(id);
        const poster = el.querySelector('img.poster');
        const ytVidId = document.getElementById(youtubeVideoId);
        const button = el.querySelector('button');
        const watch = el.querySelector('div.watch');

        const ytPlayer = YouTubePlayer(ytVidId, {
            width: 'auto',
            height: 'auto',
            videoId: ytVidId.id,
            controls: 0,
            autoplay: 0,
        });

        function playVid() {
            button.style.display = 'none';
            poster.style.display = 'none';
            watch.style.display = 'none';
            ytPlayer.playVideo();
        }

        button.addEventListener('click', playVid);
    }
}
