import Swiper, { Controller, Navigation, Pagination } from 'swiper';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const swiperOneHandle = el.querySelector('.swiper-1');
        const swiperTwoHandle = el.querySelector('.swiper-2');
        const headingsCont = el.querySelector('.benefits-carousel__carousel-cont__headings');
        const headings = headingsCont.querySelectorAll('h3');
        const next = el.querySelector('.swiper-button-next');
        const prev = el.querySelector('.swiper-button-prev');
        const pagination = el.querySelector('.swiper-pagination');

        // Initialize swiper1
        const swiper1 = new Swiper(swiperOneHandle, { // eslint-disable-line no-new
            modules: [Controller, Navigation, Pagination],
            loop: true,
            loopedSlides: 2,
            speed: 1,
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            pagination: {
                el: pagination,
                type: 'fraction',
            },
            breakpoints: {
                992: {
                    allowTouchMove: false,
                },
                2000: {
                    spaceBetween: 200,
                },
            },
            on: {
                init: function () {
                    document.addEventListener('click', e => {
                        if (e.target.matches('.swiper-button-prev')) {
                            swiper1.slidePrev();
                            swiper2.slidePrev();
                        }
            
                        if (e.target.matches('.swiper-button-next')) {
                            swiper1.slideNext();
                            swiper2.slideNext();
                        }
                    });
                }
            }
        });

        const setActiveClass = () => {
            // Convert headings to an array
            const headingsArray = Array.from(headings);
        
            // Remove active class from all list items
            headingsArray.forEach((heading) => {
                heading.classList.remove('is-active');
            });
        
            // Get the index of the active item
            const activeIndex = swiper1.realIndex;
        
            // Reorder the headings array based on the active index
            const reorderedHeadings = [
                headingsArray[activeIndex], // Current active item
                ...headingsArray.slice(activeIndex + 1), // Items after the active item
                ...headingsArray.slice(0, activeIndex), // Items before the active item
            ];
        
            // Update the headings container with the reordered headings
            headingsCont.innerHTML = '';
            reorderedHeadings.forEach((heading) => {
                headingsCont.appendChild(heading);
            });
        
            // Find the index of the current active item in the reordered array
            const currentActiveIndex = reorderedHeadings.findIndex((heading) =>
                heading.classList.contains('is-active')
            );
        
            // Check if currentActiveIndex is valid
            if (currentActiveIndex !== -1) {
                // Shift the array to the right until the current active item is at index 0
                const shiftedHeadings = [
                    ...reorderedHeadings.slice(currentActiveIndex),
                    ...reorderedHeadings.slice(0, currentActiveIndex),
                ];
        
                // Add active class to the current active item
                shiftedHeadings[0].classList.add('is-active');
            } else {
                // If currentActiveIndex is -1, set the active class to the first item
                reorderedHeadings[0].classList.add('is-active');
            }
        };
        
        swiper1.on('slideChange', () => {
            setActiveClass();
        });
        
        // Initialize swiper2
        const swiper2 = new Swiper(swiperTwoHandle, { // eslint-disable-line no-new
            modules: [Controller],
            loop: true,
            loopedSlides: 2,
            speed: 1,
            slidesPerView: 1,
            allowTouchMove: false,
        });

        // Set the controller parameter
        swiper1.controller.control = swiper2;
    }
}
