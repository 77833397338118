import JSShare from 'js-share'

export default class {
    constructor({
        id,
        docs,
        heading,
        actions,
        events,
    }) {
        const el = document.getElementById(id)
        const modalTrigger = el.querySelector('button')

        function handleModalTrigger() {
            fetch(`/shared/docs-modal?${new URLSearchParams({ heading: heading, docs: docs })}`)
                .then(response => response.json())
                .then(json => {
                    const { docsMarkup: markup } = json

                    events.emit(actions.loadModal, { markup, bg: 'md' })
                })
                .catch(err => console.log(err)) // eslint-disable-line no-console
        }

        function handleSocialShare() {
            const shareItems = document.querySelectorAll('.social-share')
            for (let i = 0; i < shareItems.length; i += 1) {
                shareItems[i].addEventListener('click', function share() {
                    return JSShare.go(this)
                })
            }
        }

        if (modalTrigger) {
            modalTrigger.addEventListener('click', handleModalTrigger)
        }
        handleSocialShare()
    }
}
