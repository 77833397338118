export default class {
    constructor({
        id,
        accordionHandle,
        accordionTriggerHandle,
        defaultAccordionState,
    }) {
        const el = document.getElementById(id);
        const trigger = el.querySelector(accordionTriggerHandle);
        const accordionState = defaultAccordionState; // boolean

        function handleAccordionState() {
            const accordion = el.querySelector(accordionHandle);
            const button = accordion.querySelector('button');

            accordion.classList.toggle('active', accordionState);
            button.classList.toggle('hidden', accordionState);
        }

        function toggleAccordion(e) {
            const accordion = e.target.closest(accordionHandle);
            const button = accordion.querySelector('button');
        
            accordion.classList.toggle('active');
            button.classList.toggle('hidden');
        }
        
        handleAccordionState();
        trigger.addEventListener('click', toggleAccordion);
    }
}
