export default class {
    constructor({
        id,
        foldHandle,
    }) {
        const el = document.getElementById(id);
        const triggers = el.querySelectorAll(foldHandle);

        function openFold(e) {
            let currentButton = e.currentTarget.querySelector('button');
            let currentFold = e.currentTarget.nextElementSibling;
            currentButton.classList.toggle('rotate');
            currentFold.classList.toggle('open');
        }

        Array.from(triggers).forEach(t => {
            t.addEventListener('click', openFold);
        })
    }
}
