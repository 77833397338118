export default class {
    constructor({
        id,
        iframeId,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const iframe = el.querySelector(`#${iframeId}`);

        // Event handler functions
        function handleMessage(e) {
            // message that was passed from iframe page
            let message = e.data;

            iframe.style.height = message.height + 'px';
            // iframe.style.width = message.width + 'px';
        }

        // Add event listeners
        window.addEventListener('message', handleMessage, false);
    }
}
