export default class {
    constructor({
        id,
        faqHandle,
        faqTriggerHandle,
    }) {
        const el = document.getElementById(id);
        const trigger = el.querySelectorAll(faqTriggerHandle);
        
        function toggleAccordion(e) {
            const li = e.target.closest(faqHandle);
            const buttons = li.querySelectorAll('button');
        
            li.classList.toggle('active');
            buttons.forEach(button => button.classList.toggle('hidden'));
        }
        
        Array.from(trigger).forEach(t => {
            t.addEventListener('click', toggleAccordion);
        });
    }
}
