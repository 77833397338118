export default class {
    constructor({
        id,
        filtersHandle,
        dropdownHandle,
        blogContHandle,
        featHandle,
        paginationContHandle,
        refresh,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const filterToggle = el.querySelector(filtersHandle);
        const dropdowns = form ? form.querySelectorAll(`${dropdownHandle} > input`) : null;
        const filters = form ? form.querySelectorAll('label > input[name]') : null;
        const blogCont = el.querySelector(blogContHandle);
        const featCard = el.querySelector(featHandle);
        const blogContLand = el.querySelector(`${blogContHandle}.landing`);
        const paginationCont = el.querySelector(paginationContHandle);

        function handleFilterToggle() {
            const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
            if (viewportWidth <= 1123) {
                filterToggle.classList.toggle('hidden');
            }
        }
        function handleDropdown({ currentTarget }) {
            dropdowns.forEach(d => {
                d.checked = d.id === currentTarget.id && currentTarget.checked;
            });
        }
        function handleFilter({ currentTarget }) {
            const dropdown = currentTarget.closest(dropdownHandle);
            const dropdownInput = dropdown.querySelector('input');
            const dropdownText = dropdown.querySelector('label > span');
            const activeFilter = currentTarget.nextElementSibling;
            const adjacentFilters = dropdown.querySelectorAll('.scroll span');

            adjacentFilters.forEach(a => {
                a.parentElement.classList.toggle('highlight', a === activeFilter);
            })

            dropdownInput.checked = false;
            dropdownText.innerHTML = `${dropdownText.getAttribute('data-title')} <span>${activeFilter.textContent}</span>`;

            const formData = new FormData(form);

            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({ // eslint-disable-line no-undef
                    event: 'getRclassEvents',
                    eventCategory: 'BlogFilter',
                    eventAction: 'click',
                    eventLabel: currentTarget.getAttribute('data-event-label'),
                    eventValue: currentTarget.getAttribute('data-event-value'),
                });
            }

            fetch(`/shared/blog-listing?${new URLSearchParams(formData)}`)
                .then(response => response.json())
                .then(json => {
                    const { blogListingsMarkup, paginationLinksMarkup } = json;

                    /**
                     * Not sure why our Lint is yelling at &&
                     * Expected an assignment or function call and instead saw an expression
                     */
                    // featCard && featCard.remove();
                    if (featCard) {
                        featCard.remove()
                    }
                    // blogContLand && blogContLand.remove();
                    if (blogContLand) {
                        blogContLand.remove()
                    }
                    blogCont.innerHTML = blogListingsMarkup;
                    paginationCont.innerHTML = paginationLinksMarkup;
                    refresh(blogCont);
                })
                .catch(err => console.log(err)); // eslint-disable-line no-console
        }

        if (dropdowns && filters) {
            dropdowns.forEach(d => {
                d.addEventListener('change', handleDropdown);
            });
            filters.forEach(f => {
                f.addEventListener('change', handleFilter);
            });
        }

        filterToggle.addEventListener('click', handleFilterToggle);
    }
}
