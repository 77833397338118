import YouTubePlayer from 'youtube-player';

export default class {
    constructor({
        id,
        videoId,
        ytIframeHandle,
    }) {
        const el = document.getElementById(id);
        const poster = el.querySelector('img.poster');
        const ytIframe = el.querySelector(ytIframeHandle);
        const playerCont = ytIframe.parentElement;
        const ytPlayer = YouTubePlayer(ytIframe, {
            width: 'auto',
            height: 'auto',
            videoId,
            host: 'https://www.youtube-nocookie.com',
            controls: 0,
            playerVars: {
                controls: 0,
                loop: 1,
                playsinline: 1,
            },
        });

        ytPlayer.on('ready', event => {
            event.target.mute(); // needed for mobile autoplay
            event.target.playVideo();
            event.target.setVolume(0);
        });

        ytPlayer.on('stateChange', event => {
            if (event.data === 0) {
                ytPlayer.playVideo();
            }
            if (event.data === 1) {
                poster.classList.add('hidden');
            }
            if (event.data === 2) {
                ytPlayer.playVideo();
            }
        });

        function handleResize() {
            ytPlayer.getIframe().then(iframe => {
                if (playerCont.offsetWidth / playerCont.offsetHeight > 16 / 9) {
                    iframe.style.width = '100%';
                    iframe.style.height = 'auto';
                } else {
                    iframe.style.width = 'auto';
                    iframe.style.height = '100%';
                }
            });
        }

        window.addEventListener('resize', handleResize);

        ytPlayer.playVideo();
        handleResize();
    }
}
