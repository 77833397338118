import YouTubePlayer from 'youtube-player';
import Swiper, { Navigation } from 'swiper';

export default class {
    constructor({
        id,
        url,
        videoId,
        ytIframeHandle,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const ytIframe = el.querySelector(ytIframeHandle);
        const swiperHandle = el.querySelector('.swiper');
        const next = el.querySelector('.swiper-button-next');
        const prev = el.querySelector('.swiper-button-prev');

        if (ytIframe !== null) {
            const playerCont = ytIframe.parentElement;

            const ytPlayer = YouTubePlayer(ytIframe, {
                width: 'auto',
                height: 'auto',
                videoId,
                host: 'https://www.youtube-nocookie.com',
                controls: 0,
                playerVars: {
                    controls: 0,
                    loop: 1,
                    playsinline: 1,
                },
            });

            ytPlayer.on('ready', event => {
                event.target.mute(); // needed for mobile autoplay
                event.target.playVideo();
                event.target.setVolume(0);
            });
    
            ytPlayer.on('stateChange', event => {
                if (event.data === 0) {
                    ytPlayer.playVideo();
                }
                if (event.data === 1) {
                    ytPlayer.playVideo();
                }
            });

            function handleResize() {
                ytPlayer.getIframe().then(iframe => {
                    if (playerCont.offsetWidth / playerCont.offsetHeight > 16 / 9) {
                        iframe.style.width = '100%';
                        iframe.style.height = 'auto';
                    } else {
                        iframe.style.width = 'auto';
                        iframe.style.height = '100%';
                    }
                });
            }
    
            window.addEventListener('resize', handleResize);
    
            ytPlayer.playVideo();
            handleResize();
        }

        new Swiper(swiperHandle, { // eslint-disable-line no-new
            modules: [Navigation],
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            loop: true,
            spaceBetween: 20,
            slidesPerView: 1.5,
            breakpoints: {
                576: {
                    slidesPerView: 1.75,
                },
                768: {
                    slidesPerView: 2.5,
                },
                1024: {
                    slidesPerView: 2.75,
                },
                1200: {
                    slidesPerView: 3.75,
                }
            },
            on: {
                init: function () {
                    const blogModalTriggers = el.querySelectorAll('button.blog');
                    const videoModalTriggers = el.querySelectorAll('button.video');
        
                    function handleBlogModalTrigger(e) {
                        const trigger = e.target;
                        const href = trigger.getAttribute('data-href');
                        
                        fetch(`/shared/blog-splash?${new URLSearchParams({ redirectUrl: href })}`)
                            .then(response => response.json())
                            .then(json => {
                                const { blogSplashMarkup: markup } = json;
            
                                events.emit(actions.loadModal, { markup });
                            })
                            .catch(err => console.log(err)); // eslint-disable-line no-console
                    }

                    function handleVideoModalTrigger(e) {
                        const trigger = e.target;
                        const href = trigger.getAttribute('data-href');

                        fetch(`/shared/video-splash?${new URLSearchParams({ redirectUrl: href })}`)
                            .then(response => response.json())
                            .then(json => {
                                const { videoSplashMarkup: markup } = json;
            
                                events.emit(actions.loadModal, { markup });
                            })
                            .catch(err => console.log(err)); // eslint-disable-line no-console
                    }
        
                    blogModalTriggers.forEach(trigger => {
                        trigger.addEventListener('click', handleBlogModalTrigger);
                    });
        
                    videoModalTriggers.forEach(trigger => {
                        trigger.addEventListener('click', handleVideoModalTrigger);
                    });
                }
            }
        });
    }
}