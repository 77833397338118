export default class {
	constructor({
		id,
		productsContHandle,
		productHandle,
		closeHandle,
		activeClass,
	}) {
		const el = document.getElementById(id);
		const dots = Array.from(el.querySelectorAll('[data-dotsid]'));
		const productsCont = el.querySelector(productsContHandle);
        const products = el.querySelectorAll(productHandle);
		const closes = el.querySelectorAll(closeHandle);

        function handleOpenDots(p) {
            let pid = p.getAttribute('data-productid');

            dots.forEach((d) => {
                d.classList.toggle(activeClass, d.getAttribute('data-dotsid') === pid);
            });
        }
        function handleCloseDots() {
            dots.forEach((d) => {
                d.classList.remove(activeClass);
            });
        }
        function handleOpenProducts(i) {
            productsCont.classList.add(activeClass);
            productsCont.setAttribute('data-active-product', i + 1);
        }
        function handleCloseProducts(e) {
            products.forEach((p) => {
                p.classList.remove(activeClass);
            });
            productsCont.classList.remove(activeClass);
            productsCont.removeAttribute('data-active-product');

            handleCloseDots();
            e.stopPropagation();
        }
		const handleOpenTriggers = Array.from(products).map((p, i) => () => {
			products.forEach((pp, ii) => {
                pp.classList.toggle(activeClass, i === ii);
			});

            handleOpenProducts(i);
            handleOpenDots(p);
		});

		products.forEach((p, i) => {
			p.addEventListener('click', handleOpenTriggers[i]);
		});
        closes.forEach((o) => {
			o.addEventListener('click', handleCloseProducts);
		});
	}
}
