export default class {
    constructor({
        id,
        tabsHandle,
        tabContentHandle,
    }) {
        const el = document.getElementById(id);
        const tabs = el.querySelector(tabsHandle);
        const tabLinks = tabs.querySelectorAll('.tabs li a');
        const tabContent = tabs.querySelectorAll(tabContentHandle);

        // Apply active link for tab last clicked
        tabLinks.forEach(t => {
            t.addEventListener('click', e => {
                e.preventDefault();

                tabLinks.forEach(l => l.classList.remove('active'));
                tabContent.forEach(c => c.classList.remove('active'));

                const target = el.querySelector(t.getAttribute('href'));
                t.classList.add('active');
                target.classList.add('active');
            });
        });
    }
}
