export default class {
    constructor({
        id,
        url,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const blogModalTrigger = el.querySelector('button.blog');
        const videoModalTrigger = el.querySelector('button.video');

        function handleBlogModalTrigger(e) {
            const trigger = e.target;
            const href = trigger.getAttribute('data-href');
            
            fetch(`/shared/blog-splash?${new URLSearchParams({ redirectUrl: href })}`)
                .then(response => response.json())
                .then(json => {
                    const { blogSplashMarkup: markup } = json;

                    events.emit(actions.loadModal, { markup });
                })
                .catch(err => console.log(err)); // eslint-disable-line no-console
        }

        function handleVideoModalTrigger(e) {
            const trigger = e.target;
            const href = trigger.getAttribute('data-href');

            fetch(`/shared/video-splash?${new URLSearchParams({ redirectUrl: href })}`)
                .then(response => response.json())
                .then(json => {
                    const { videoSplashMarkup: markup } = json;

                    events.emit(actions.loadModal, { markup });
                })
                .catch(err => console.log(err)); // eslint-disable-line no-console
        }

        if (blogModalTrigger) {
            blogModalTrigger.addEventListener('click', handleBlogModalTrigger);
        }

        if (videoModalTrigger) {
            videoModalTrigger.addEventListener('click', handleVideoModalTrigger);
        }
    }
}