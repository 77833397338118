export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const formSet = el.querySelector('form.set > div');
        const formSetSelect = formSet.querySelector('span');
        const selectedOption = formSet.querySelector('span > span');
        const dropdown = formSet.querySelector('ul');
        const languages = dropdown.querySelectorAll('label');
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        if (viewportWidth <= 1023) {
            formSetSelect.addEventListener('click', e => { /* eslint-disable-line no-unused-vars */
                dropdown.classList.toggle('opened')
            });
        }

        for (var i = 0; i < languages.length; i++) {
            languages[i].addEventListener('click', e => {
                selectedOption.innerText = e.currentTarget.getAttribute('data-active-text');
                if (viewportWidth <= 1023) {
                    dropdown.classList.remove('opened');
                }
            });
        }
    }
}
