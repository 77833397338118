export default class {
    constructor({
        id,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const close = el.querySelector('button.close');

        function handleClose() {
            events.emit(actions.closeModal);
        }

        close.addEventListener('click', handleClose);
    }
}
