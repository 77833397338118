export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);

        function handleClick() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }

        window.addEventListener('scroll', function() {
            if (window.pageYOffset > 100) {
                el.classList.add('show');
            } else {
                el.classList.remove('show');
            }
        });

        el.addEventListener('click', handleClick);
    }
}
