import Swiper, { Navigation, Pagination } from 'swiper';

export default class {
    constructor({
        id,
        //slideCount,
    }) {
        const el = document.getElementById(id);
        const swiperHandle = el.querySelector('.swiper');

        new Swiper(swiperHandle, { // eslint-disable-line no-new
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            spaceBetween: 40,
            breakpoints: {
                375: {
                    slidesOffsetBefore: 0,
                    slidesPerView: 1.25,
                    spaceBetween: 12,
                },
                768: {
                    slidesOffsetBefore: 0,
                    slidesPerView: 1.25,
                },
                1024: {
                    spaceBetween: 30,
                }
            }
        });
    }
}
