export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const links = el.querySelectorAll('a');

        function getBaseUrl(url) {
            url = url.split('://')[1]; /* eslint-disable-line prefer-destructuring */
            url = url.split('#')[0]; /* eslint-disable-line prefer-destructuring */
            url = url.split('?')[0]; /* eslint-disable-line prefer-destructuring */
            url = url.replace(/\/$/, '');

            return url;
        }

        function handleClick(e) {
            const hash = this.hash.replace('#', '');
            const target = document.getElementById(hash);

            const linkUrl = getBaseUrl(this.href);
            const currentUrl = getBaseUrl(window.location.href);

            if (target !== null && linkUrl === currentUrl) {
                e.preventDefault();

                window.scrollTo({
                    top: target.offsetTop - el.offsetHeight,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }

        window.addEventListener('scroll', function() {
            if (window.pageYOffset > 100) {
                el.classList.add('shift');
            } else {
                el.classList.remove('shift');
            }
        });

        Array.from(links).forEach(l => {
            l.addEventListener('click', handleClick);
        });
    }
}
