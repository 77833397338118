import { router } from 'ui-utilities';
import Swiper, { Navigation, Pagination } from 'swiper';

export default class {
    constructor({
        id,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const swiperHandle = el.querySelector('.swiper');
        const next = el.querySelector('.swiper-button-next');
        const prev = el.querySelector('.swiper-button-prev');
        const pagination = el.querySelector('.swiper-pagination');

        new Swiper(swiperHandle, { // eslint-disable-line no-new
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            pagination: {
                el: pagination,
            },
            centeredSlides: true,
            loop: true,
            spaceBetween: 28,
            breakpoints: {
                428: {
                    slidesPerView: 1.04,
                    spaceBetween: 36,
                },
                768: {
                    slidesPerView: 1.2,
                    spaceBetween: 80,
                },
                1024: {
                    slidesPerView: 1.592,
                    spaceBetween: 80,
                },
                1440: {
                    slidesPerView: 1.892,
                    spaceBetween: 100,
                },
                2100: {
                    slidesPerView: 1.892,
                    spaceBetween: 100,
                },
            },
            on: {
                init: function () {
                    const links = el.querySelectorAll('a');
                    const videos = Array.from(links).filter(link => link.href.includes('/videos/video/'));

                    const handleVideos = Array.from(videos).map(video => e => {
                        e.preventDefault();
            
                        const { href } = video;
                        const parts = href.split('/');
                        const videoId = parts[parts.length - 1];
                        const url = `${href}?videoId=${videoId}`;
            
                        function cb(res) {
                            const markup = res;
            
                            events.emit(actions.loadModal, { markup });
                        }
            
                        router.get({ url, cb });
                    });

                    Array.from(videos).forEach((video, i) => {
                        video.addEventListener('click', handleVideos[i]);
                    });
                }
            }
        });
    }
}
