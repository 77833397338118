export default class {
    constructor({
        id,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const thumbs = el.querySelectorAll('.builder-profiles-carousel__carousel-nav li');
        const slides = el.querySelectorAll('.builder-profiles-carousel__carousel-content > div');

        document.addEventListener('click', function (event) {
            if (event.target.matches('.builder-profiles-carousel__carousel-nav li')) {
                // scroll to the bottom of the carousel;
                // this prevents layout shift on mobile devices
                setTimeout(() => {
                    el.querySelector('.builder-profiles-carousel__carousel').scrollIntoView({
                        behavior: 'smooth',
                        block: 'end'
                    });
                }, 500);
                
                // toggle active classes
                thumbs.forEach(thumb => thumb.classList.remove('active'));
                event.target.classList.add('active');
                
                // swap out main content
                slides.forEach(slide => slide.classList.remove('active'));
                el.querySelector(`.builder-profiles-carousel__slide.slide--${event.target.classList[1].substring(5)}`).classList.add('active');
            }
        });
    }
}
