export default class {
    constructor({
        id,
        applicationDetailIntroHandle,
        applicationDetailIntroTextHandle,
        applicationDetailIntroFeaturedProductsHandle,
        applicationDetailIntroCarouselHandle,
    }) {
        const el = document.getElementById(id);
        const cont = el.querySelectorAll(applicationDetailIntroHandle);

        function handleCarouselHeight() {
            cont.forEach(c => {
                const text = c.querySelector(applicationDetailIntroTextHandle);
                const products = c.querySelector(applicationDetailIntroFeaturedProductsHandle);
                const carousel = c.querySelector(applicationDetailIntroCarouselHandle);
        
                const textHeight = text.offsetHeight;
                const productsHeight = products.offsetHeight;
                const sumHeight = textHeight + productsHeight;
        
                if (window.innerWidth >= 1024) {
                    carousel.style.height = `${sumHeight}px`;
                } else {
                    carousel.style.height = '360px';
                }
            });
        }

        handleCarouselHeight();
        window.addEventListener('resize', handleCarouselHeight);
    }
}
