export default class {
    constructor({
        id,
        errorClass,
        name,
        formId,
        conditionalName,
        conditionalValue,
        scrollerHandle,
        actions,
        // translations,
    }) {
        const el = document.getElementById(id);
        const input = el.querySelector(`[name="${name}"]`);
        const checkboxes = el.querySelectorAll(`[type="checkbox"]`);
        const error = el.querySelector('p');
        // console.log(error);
        const form = el.closest('form');
        const targets = conditionalName
            ? form.querySelectorAll(`[name="${conditionalName}"]`)
            : [];
        const scroller = scrollerHandle !== '' ? document.querySelector(scrollerHandle) : null;

        function toggleVisibility(visible) {
            el.style.display = visible ? 'block' : 'none';
            // Need to hide parent container for form column formatting
            el.parentElement.style.display = visible ? 'block' : 'none';
        }

        function handleShowInputErrors(e) {
            const {
                name: n,
                errors,
                formId: f = '',
            } = e.detail;

            // Don't process input errors for other forms or other inputs
            if (f !== formId || n !== name) {
                return;
            }

            el.classList.toggle(errorClass, errors.length);
            input.classList.toggle(errorClass, errors.length);
            error.innerHTML = errors.join("\n"); // eslint-disable-line quotes

            // error.forEach(p => {
            //     const text = p.innerText;
            //     const translation = translations[text];

            //     if (translation !== '' && translation !== undefined) {
            //         p.innerText = p.innerText.replace(text, translation);
            //     }
            // });
        }
        function handleTargetChange(e) {
            toggleVisibility(e.currentTarget.value === conditionalValue);
        }

        window.addEventListener(actions.showInputErrors, handleShowInputErrors);
        targets.forEach(target => {
            target.addEventListener('change', handleTargetChange);
        });

        // Initalize conditional formatting
        if (conditionalName) {
            const formData = new FormData(form);

            toggleVisibility(formData.get(conditionalName) === conditionalValue);
        }

        function isScrolled(e) {
            if (e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight) {
                el.classList.remove('input--disabled');
                el.classList.remove('input--readonly');

                if (input) {
                    input.removeAttribute('disabled');
                    input.removeAttribute('readonly');
                }

                if (checkboxes.length > 0) {
                    checkboxes.forEach(o => {
                        o.removeAttribute('disabled');
                        o.removeAttribute('readonly');
                    });
                }
            }
        }

        if (scroller) {
            scroller.addEventListener('scroll', function (e) {
                isScrolled(e);
            });
        }
    }
}
