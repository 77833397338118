export default class {
    constructor({
        id,
        blogUrl,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const modalTrigger = el.querySelector('button');

        function handleModalTrigger() {
            fetch(`/shared/blog-splash?${new URLSearchParams({ redirectUrl: blogUrl })}`)
                .then(response => response.json())
                .then(json => {
                    const { blogSplashMarkup: markup } = json;

                    events.emit(actions.loadModal, { markup });
                })
                .catch(err => console.log(err)); // eslint-disable-line no-console
        }

        modalTrigger.addEventListener('click', handleModalTrigger);
    }
}
